import * as React from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
  StyledInput,
  FormOverlay,
  Wrapper,
  StyledLabel,
  StyledButton,
} from '../Contact/styled-components';

const Contact = () => {
  const router = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const email = data.get('email');
    const organization = data.get('organization');
    if (!firstName || !lastName || !email || !organization) {
      toast.error(
        'First Name, Last Name, Email, and Organization are required.'
      );
      return;
    }

    axios
      .post('/form', {
        firstName,
        lastName,
        email,
        organization,
      })
      .then((res) => {
        if (res) {
          toast.success('Success!');

          router('/success');
        } else {
          router('/success');
        }
      })
      .catch((err) => {
        router('/success');
      });
  };

  return (
    <Wrapper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100svh',
      }}
    >
      <FormOverlay>
        <form onSubmit={handleSubmit} id="contactForm">
          <h1>Dexcom Camp Donation Form</h1>
          <StyledLabel htmlFor="firstName">*First Name:</StyledLabel>
          <br />
          <StyledInput type="text" id="firstName" name="firstName" />
          <br />
          <StyledLabel htmlFor="lastName">*Last Name:</StyledLabel>
          <br />
          <StyledInput type="text" id="lastName" name="lastName" />
          <br />
          <StyledLabel htmlFor="email">*Email:</StyledLabel>
          <br />
          <StyledInput type="text" id="email" name="email" />
          <br />
          <StyledLabel htmlFor="organization">*Organization:</StyledLabel>
          <br />
          <StyledInput type="text" id="organization" name="organization" />
          <br />
          <br />

          <StyledButton
            style={{ maxWidth: '100%' }}
            type="submit"
            value="Submit"
          >
            Submit
          </StyledButton>
        </form>
      </FormOverlay>
    </Wrapper>
  );
};

export default Contact;
