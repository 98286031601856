import * as React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Wrapper,
  Link,
  Logo,
  LinkWrapper,
  LogoSpacePlaceholder,
  ExternalLink,
} from './styled-components';

import logo from '../../images/logoSmall.png';

const NavBar = () => {
  const { pathname } = useLocation();

  if (pathname === '/success') {
    return null;
  }

  return (
    <Wrapper>
      <Logo src={logo} alt="CampViews Logo" />
      <LinkWrapper>
        <Link $isActive={pathname === '/'} to="/">
          Home
        </Link>
        <Link $isActive={pathname === '/faqs'} to="/faqs">
          FAQs
        </Link>
        <Link $isActive={pathname === '/contact'} to="/contact">
          Contact
        </Link>
        <Link $isActive={pathname === '/register'} to="/register">
          Register
        </Link>
        <Link $isActive={pathname === '/download'} to="/download">
          Download
        </Link>
        <ExternalLink
          color="cream"
          href="https://collectcheckout.com/r/ogkrh"
          target={'_blank'}
        >
          Payment
        </ExternalLink>
      </LinkWrapper>
      <LogoSpacePlaceholder />
    </Wrapper>
  );
};

export default NavBar;
