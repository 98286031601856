import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Home, Faqs, Contact, Register, Download, Form } from './pages';
import NavBar from './components/NavBar';
import Footer from './components/Footer/Footer';

import { data } from './base64pdf';

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Toaster
        containerStyle={{
          top: 60,
        }}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/download" element={<Download />} />
        <Route path="/form" element={<Form />} />
        <Route
          path="/success"
          element={
            <>
              <iframe
                src="https://drive.google.com/file/d/1ZQAUoexpCiWq6f7XoIhvfdocE-lii7g7/preview"
                title="Dexcom Camp Donation form"
                style={{ width: '100%', height: '100svh' }}
              />

              <a
                href={`data:application/octet-stream;base64,${data}`}
                download="DexcomCampDonationForm.pdf"
                style={{
                  position: 'absolute',
                  bottom: 60,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  borderRadius: '25px',
                  padding: '1rem',
                  background: 'black',
                  color: 'white',
                  opacity: 0.8,
                  textAlign: 'center',
                }}
              >
                Download
              </a>
            </>
          }
        />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
